<template>
  <div class="frameWithHome">
    <ul>
      <li @click="goOpen('ERP_SellURL_Lens', 'lens')">
        <img src="@/assets/img/frameWith/3DScan_2.png" alt="">
        <p>镜片适配选择</p>
      </li>
      <li @click="goOpen('ERP_SellURL_Frame', 'frame')">
        <img src="@/assets/img/frameWith/3DScan_3.png" alt="">
        <p>框架适配选</p>
      </li>
      <li @click="go('/settlementCenter')">
        <img src="@/assets/img/frameWith/3DScan_5.png" alt="">
        <p>框架配镜处方</p>
      </li>
      <li>
        <img src="@/assets/img/frameWith/3DScan_6.png" alt="">
        <p>订单&加工</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "frameWithHome",
  data() {
    return {

    }
  },
  methods: {
    go(url) {
      this.$router.push({path: url})
    },
    goOpen(val, type) {


      let arrs = this.$store.state.users.user.Company.ListCompanyConfig, str = ''
      let isz = false
      for (let i = 0; i < arrs.length; i++) {
        if (arrs[i].BCKey == 'ShowSelfProductInZTHT' && arrs[i].BCValue == 1) {
          isz = true
          break
        }
      }

      if (!arrs.some(item => item.BCKey == 'ERPSource') || isz) {
        this.$router.push({path: '/frameWithHome/frameChoose', query: {
            type: type
          }})
        return
      }


      if (!this.$store.state.users.CsUser || !this.$store.state.physicianVisits.xzConsulting) {
        return this.$alert('请客服登陆，选择咨询或新建咨询！', '提示')
            .then(res => {
              this.$router.push({path: '/csLanding'})
            })
      }

      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登陆！请先登陆', '提示')
            .then(res => {
              this.$router.push({path: '/employeesLanding'})
            })
      }


      for (let i = 0; i < arrs.length; i++) {
        if (arrs[i].BCKey == val) {
          str = arrs[i].BCValue
        }
      }


      // let csid = this.$store.state.users.CsUser.CustomerId,
      //     chosid = this.$store.state.physicianVisits.xzConsulting,
      //     usid = this.$store.state.users.user.ShopUserLoginWithTypeResult.UserId,
      //     exid = this.$store.state.users.employees.ExpertId,
      //     shopid = this.$store.state.bases.shop.ShopId,
      //     excode = this.$store.state.users.employees.ListERPCode[0]?this.$store.state.users.employees.ListERPCode[0].ObjectCode:''
      // if (!excode) return this.$alert('医生数据错误！', '提示')
      // let last = str.split('?')[1].split('=')
      // str = str?str.split('?')[0]:'a'

      let arr = this.$store.state.users.user.Company.ListCompanyConfig
      let yg = this.$store.state.users.employees,
          csID = this.$store.state.users.CsUser.CustomerId,
          chosID = this.$store.state.physicianVisits.xzConsulting,
          uid = this.$store.state.users.user.UserId,
          shopID = this.$store.state.bases.shop.ShopId
      if (str && str.length > 0) {
        let obj = '',
            ERP_com = '',
            yscode = yg.ListERPCode[0].ObjectCode ? yg.ListERPCode[0].ObjectCode : ''
        ERP_com = arr.filter(item => item.BCKey == 'ERP_com')
        if (ERP_com && ERP_com.length > 0) {
          ERP_com = ERP_com[0].BCValue
        }
        if (!yg.ListERPCode) {
          return this.$alert('暂无权限', '提示')
        }
        let url = str
        if (url) {
          url = url.replace('{ERP_com}', ERP_com)
          url = url.replace('{Expert_Code}', yscode)
          url = url.replace('{CustomerId}', csID)
          url = url.replace('{ChooseId}', chosID)
          url = url.replace('{COAddUserId}', uid)
          url = url.replace('{COExpertId}', yg.ExpertId)
          url = url.replace('{COShopId}', shopID)
        }
        window.open(url)
      }else {
        this.$alert('暂无权限', '提示')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.frameWithHome {
  width: 100vw;
  height: 76vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
h3 {padding: .5rem 0;}
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 0 1rem;
    cursor: pointer;
    img {
      display: block;
      width: 150px;
      margin-bottom: .2rem;
    }
  }
}
.pd-4 {padding-top: 4vh}
</style>
